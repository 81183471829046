<template>
  <a-row justify="center" align="middle" style="height: 100vh; padding: 20px;">
    <a-col :span="16">
      <a-card>
        <!-- WebSocket Connection -->
        <a-row gutter="16">
          <a-col :span="6" class="align-end">
            <span>本地管理端</span>
          </a-col>
          <a-col :span="12">
            <a-input v-model="url" placeholder="请输入 WebSocket 地址" />
          </a-col>
          <a-col :span="6">
            <a-button type="primary" @click="connect" block>连接</a-button>
          </a-col>
        </a-row>
        <a-row gutter="16" style="margin-top: 20px;">
          <a-col :span="24">
            <span>WebSocket 状态: {{ wsStatus }}</span>
          </a-col>
        </a-row>

        <!-- Team Creation -->
        <a-row gutter="16" style="margin-top: 20px;">
          <a-col :span="6" class="align-end">
            <span>队伍人数</span>
          </a-col>
          <a-col :span="12">
            <a-slider v-model="teamSize" :min="1" :max="4" />
          </a-col>
          <a-col :span="2" style="margin-left: 80px;">
            <a-input-number v-model="teamSize" :min="1" :max="4" style="width: 58px;" />
          </a-col>
        </a-row>
        <a-row gutter="16" style="margin-top: 20px;">
          <a-col :span="6" class="align-end">
            <span>设备标识</span>
          </a-col>
          <a-col :span="12">
            <a-input v-model="deviceIdentifier" placeholder="请输入设备标识" />
          </a-col>
        </a-row>
        <a-row gutter="16" style="margin-top: 20px;" justify="center">
          <a-col :span="12">
            <a-button type="primary" @click="createTeam" block>创建队伍</a-button>
          </a-col>
        </a-row>

        <!-- Player Information -->
        <a-row gutter="16" style="margin-top: 20px;">
          <a-col :span="24">
            <a-divider>玩家信息</a-divider>
            <a-row>
              <a-col :span="6">玩家名称</a-col>
              <a-col :span="6">玩家ID</a-col>
              <a-col :span="6">用户二维码</a-col>
              <a-col :span="6">设备二维码</a-col>
            </a-row>
            <div v-for="(player, index) in players" :key="index" style="margin-bottom: 20px;">
              <a-card hoverable>
                <a-row>
                  <a-col :span="6">{{ player.name }}</a-col>
                  <a-col :span="6">{{ player.id }}</a-col>
                  <a-col :span="6">
                    <div
                        @mouseenter="showQRCode(index, 'user')"
                        @mouseleave="hideQRCode('user')"
                        class="qr-code-container"
                    >
                      <canvas :id="'qrcode-user-' + index" class="qr-code qr-code-user"></canvas>
                    </div>
                  </a-col>
                  <a-col :span="6">
                    <div
                        @mouseenter="showQRCode(index, 'device')"
                        @mouseleave="hideQRCode('device')"
                        class="qr-code-container"
                    >
                      <canvas :id="'qrcode-device-' + index" class="qr-code qr-code-device"></canvas>
                    </div>
                  </a-col>
                </a-row>
              </a-card>
            </div>
          </a-col>
        </a-row>

        <!-- Team ID Information -->
        <a-row gutter="16" style="margin-top: 20px;">
          <a-col :span="24">
            <a-divider>Team ID 信息</a-divider>
            <a-list>
              <a-list-item v-for="(item, index) in teamIDs" :key="index">
                <a-list-item-meta :title="'Team ID: ' + item.teamID" />
              </a-list-item>
            </a-list>
          </a-col>
        </a-row>
      </a-card>
    </a-col>
  </a-row>
</template>

<script>
import { ref, reactive, nextTick } from 'vue';
import QRCode from 'qrcode';
import { v4 as uuidv4 } from 'uuid';

export default {
  name: 'MyDashboard',
  setup() {
    const defaultUrl = 'ws://192.168.31.20:60606';
    const storedUrl = localStorage.getItem('wsUrl');
    const url = ref(storedUrl || defaultUrl);
    const teamSize = ref(2);
    const qrCodeIndex = ref(null);
    const qrCodeType = ref(null);
    const wsStatus = ref('未连接');
    const players = ref([]);
    const deviceIdentifier = ref('');
    const qrCodeHovering = ref(false);
    const teamIDs = reactive([]);

    let ws = null;
    let additionalWs = null;

    const playerNames = ['zhangsan', 'lisi', 'wangwu', 'zhaoliu'];

    const generateQRCode = (content, canvasId) => {
      const canvas = document.getElementById(canvasId);
      QRCode.toCanvas(canvas, content, function (error) {
        if (error) console.error(error);
        console.log('QR code generated successfully!');
      });
    };

    const connect = () => {
      if (ws) {
        ws.close();
      }
      if (additionalWs) {
        additionalWs.close();
      }

      ws = new WebSocket(url.value);
      ws.onopen = () => {
        wsStatus.value = '已连接';
        console.log('WebSocket 已连接');
        localStorage.setItem('wsUrl', url.value);
        connectAdditionalWs();
      };
      ws.onclose = () => {
        wsStatus.value = '已断开';
        console.log('WebSocket 已断开');
      };
      ws.onerror = (error) => {
        wsStatus.value = '连接错误';
        console.error('WebSocket 连接错误', error);
      };
      ws.onmessage = (message) => {
        console.log('WebSocket 消息', message.data);
      };
    };

    const connectAdditionalWs = () => {
      const ip = url.value.split(':')[1].replace('//', '');
      const additionalUrl = `ws://${ip}:11111`;
      additionalWs = new WebSocket(additionalUrl);

      additionalWs.onopen = () => {
        console.log('附加 WebSocket 已连接');
      };
      additionalWs.onclose = () => {
        console.log('附加 WebSocket 已断开');
      };
      additionalWs.onerror = (error) => {
        console.error('附加 WebSocket 连接错误', error);
      };
      additionalWs.onmessage = (message) => {
        const data = JSON.parse(message.data);
        if (data.Interface === 'ReqStartPlaying') {
          teamIDs.unshift({ id: uuidv4(), teamID: data.TeamID });
        }
        console.log('附加 WebSocket 消息', message.data);
      };
    };

    const createMessage = () => {
      const message = {
        cmd: 'PROTOCOL_REQ_SYNC_TEAMINFO',
        message: {
          teamName: 'Test',
          memberList: players.value.map(player => ({
            userId: player.id,
            userName: player.name,
          })),
        },
      };

      return JSON.stringify(message);
    };

    const createTeam = () => {
      if (!ws || ws.readyState !== WebSocket.OPEN) {
        connect();
      }
      players.value = [];
      for (let i = 0; i < teamSize.value; i++) {
        const id = uuidv4().replace(/-/g, '').toUpperCase();
        players.value.push({ name: playerNames[i], id });
      }
      const message = createMessage();
      console.log(message);
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.send(message);
        console.log('发送消息', message);
      } else {
        console.error('WebSocket 未连接');
      }
    };

    const showQRCode = (index, type) => {
      qrCodeIndex.value = index;
      qrCodeType.value = type;
      qrCodeHovering.value = true;
      nextTick(() => {
        if (type === 'user') {
          generateQRCode(`dhbkfg://qrcode/type=user/user=${players.value[index].id}`, `qrcode-user-${index}`);
        } else if (type === 'device') {
          generateQRCode(`dhbkfg://qrcode/type=device/name=${deviceIdentifier.value}`, `qrcode-device-${index}`);
        }
      });
    };

    const hideQRCode = (type) => {
      qrCodeHovering.value = false;
      if (qrCodeType.value === type) {
        qrCodeIndex.value = null;
        qrCodeType.value = null;
      }
    };

    return {
      url,
      teamSize,
      players,
      qrCodeIndex,
      qrCodeType,
      wsStatus,
      deviceIdentifier,
      teamIDs,
      connect,
      createTeam,
      showQRCode,
      hideQRCode,
    };
  },
};
</script>

<style scoped>
.qr-code-container {
  position: relative;
}

.qr-code {
  width: 100px;
  height: 100px;
  transition: transform 0.3s, top 0.3s, left 0.3s;
}

.qr-code-container:hover .qr-code {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(5);
  z-index: 9999;
}
</style>
